import request from '@/utils/request'


// 查询官网配置
export function config() {
  return request({
    url: '/api/yxWebsiteConfig/config',
    method: 'get',
  })
}

// 获取最新咨询 前4条
export function newInformation() {
  return request({
    url: '/api/obtain/newInformation',
    method: 'get',
  })
}


// 获取关于企业
export function getEnterprise() {
  return request({
    url: '/api/obtain/getEnterprise',
    method: 'get',
  })
}


// 获取师资力量
export function getTeachers() {
  return request({
    url: '/api/obtain/getTeachers',
    method: 'get',
  })
}


// 获取开设课程
export function getCourse() {
  return request({
    url: '/api/obtain/getCourse',
    method: 'get',
  })
}


// 合作伙伴
export function getPartner() {
  return request({
    url: '/api/obtain/getPartner',
    method: 'get',
  })
} 

export function getPartner2() {
  return request({
    url: '/api/obtain/getPartner2',
    method: 'get',
  })
}

//获取应急救护
export function getEmergency() {
  return request({
    url: '/api/obtain/getEmergency',
    method: 'get',
  })
} 

// 获取夏令营
export function getSummercamp() {
  return request({
    url: '/api/obtain/getSummercamp',
    method: 'get',
  })
} 

// 获取体育运动
export function getMotion() {
  return request({
    url: '/api/obtain/getMotion',
    method: 'get',
  })
} 

// 获取消防技术
export function getFireFighting() {
  return request({
    url: '/api/obtain/getFireFighting',
    method: 'get',
  })
} 

// 获取研学旅行
export function getStudy() {
  return request({
    url: '/api/obtain/getStudy',
    method: 'get',
  })
} 

// 获取集团新闻
export function getGroup() {
  return request({
    url: '/api/obtain/getGroup',
    method: 'get',
  })
} 

// 获取媒体报道
export function getMedia() {
  return request({
    url: '/api/obtain/getMedia',
    method: 'get',
  })
} 

// 获取行业资讯
export function getIndustry() {
  return request({
    url: '/api/obtain/getIndustry',
    method: 'get',
  })
} 

// 获取联系我们
export function getContact() {
  return request({
    url: '/api/obtain/getContact',
    method: 'get',
  })
} 

//获取招聘信息 
export function getRecruit() {
  return request({
    url: '/api/obtain/getRecruit',
    method: 'get',
  })
} 

//获取户外运动
export function getOutdoors() {
  return request({
    url: '/api/obtain/getOutdoors',
    method: 'get',
  })
} 