var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"GroupOverview"},[_c('daohang'),_c('banner2',{attrs:{"bannerH":_vm.h1,"bannerP":_vm.P1,"bannerImg":_vm.Img1}}),_c('div',{staticClass:"about-bg"},[_c('div',{staticClass:"about",attrs:{"id":"about"}},[_c('div',{staticClass:"about-top"},[_c('div',{staticClass:"about-top-l talk_bottom"},[_c('h1',[_vm._v(" 关于企业 ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.enterprise.content)}})])])])]),_c('div',{staticClass:"qywh",attrs:{"id":"CultureInfo"}},[_c('h1',[_vm._v("企业文化")]),_c('div',{staticClass:"qywh-item"},[_c('div',{staticClass:"qywh-item1"},[_vm._m(0),_c('p',{staticClass:"qywh-text"},[_vm._v("企业定位")]),_c('p',{staticClass:"qywh-text2"},[_vm._v(" "+_vm._s(_vm.configs.location)+" ")])]),_c('div',{staticClass:"qywh-item1"},[_vm._m(1),_c('p',{staticClass:"qywh-text"},[_vm._v("企业理念")]),_c('p',{staticClass:"qywh-text2 text2-2"},[_vm._v(" "+_vm._s(_vm.configs.idea)+" ")])]),_c('div',{staticClass:"qywh-item1"},[_vm._m(2),_c('p',{staticClass:"qywh-text"},[_vm._v("企业使命")]),_c('p',{staticClass:"qywh-text2"},[_vm._v(" "+_vm._s(_vm.configs.mission)+" ")])]),_c('div',{staticClass:"qywh-item1"},[_vm._m(3),_c('p',{staticClass:"qywh-text"},[_vm._v("企业愿景")]),_c('p',{staticClass:"qywh-text2"},[_vm._v(" "+_vm._s(_vm.configs.vision)+" ")])])])]),_c('div',{staticClass:"President"},[_c('div',{staticClass:"President_content"},[_vm._m(4),_c('div',{staticClass:"President-text"},[_c('p',[_vm._v(" "+_vm._s(_vm.configs.consult2)+" ")]),_vm._m(5)])])]),_c('div',{staticClass:"DevelopmentHistory",attrs:{"id":"history"}},[_c('div',{staticClass:"about",attrs:{"id":"about"}},[_c('div',{staticClass:"about-top"},[_c('div',{staticClass:"about-top-l"},[_c('h1',[_vm._v(" 师资力量 ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.enterprise.content)}})])])])]),_c('footer2')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("../assets/img/编组 5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("../assets/img/编组 10.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("../assets/img/编组 14.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("../assets/img/编组 15.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"President-title"},[_c('p',[_vm._v("总裁寄语")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"President-text2"},[_c('span',[_vm._v("执行总裁")]),_c('img',{attrs:{"src":require("../assets/img/杨刚.png"),"alt":""}})])
}]

export { render, staticRenderFns }