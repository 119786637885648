<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页> <a href="/#/xinwen"> 企业新闻</a> ><span> {{group.title}}</span></div>
		</div>
		<!--  -->
		<div class="NewsDetails">
			<div class="NewsDetails_cont">
				<div class="NewsDetails_cont_title">
					<h1>{{group.title}}</h1>
					<div class="NewsDetails_cont_title_span">
						<img src="../assets/img/newsDetail/矩形.png" alt="" /><span>发布时间：{{group.updateTime}}&ensp;&ensp;
						</span>
						<!-- <img src="../assets/img/newsDetail/矩形(1).png" alt="" /><span
							>浏览次数：12345
						</span> -->
					</div>
				</div>
				<div class="talk_bottom container" v-html="group.content"></div>
				<!-- 上一篇 下一篇 -->
				<!-- <Next ref="childData"></Next> -->
				<div class="NewsDetails_cont_footer">
					<!-- 使用三目运算符 判断按钮是否可以点击 当变量为true 不可以点击  -->
					<div @click="last" :class="lastNoShow ? 'noClick' : 'btn'">
						<img src="../assets/img/newsDetail/公共-更多2(1).png" alt="" /><span>上一篇：{{ lastTitle }}</span>
					</div>
					<!-- 使用三目运算符判断 按钮是否可以点击 -->
					<div @click="next" :class="nextNoShow ? 'noClick' : 'btn'">
						<span>下一篇：{{ nextTitle }}</span>
						<img src="../assets/img/newsDetail/公共-更多2.png" alt="" />
					</div>
				</div>

				<div class="NewsDetails_cont_btm">

				</div>

			</div>
		</div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
	import footer2 from "../components/footer.vue";
	import banner2 from "../components/banner.vue";
	import nav2 from "../components/nav.vue";
	/* import Next from "../components/NextLastArtcle.vue"; */
	import {
		getGroup
	} from "@/api/obtain";
	export default {
		name: "keji",
		components: {
			footer2,
			nav2,
			banner2,
		},
		data() {
			return {
				arrList: [],
				id: null,
				group: null,
				h1: "企业新闻",
				Img1: require("../assets/img/keji/矿山banner1.png"),
				// 这是我在父的组件里 定义的变量 表示当前是数组里第1个页面
				artcleID: 0,
				p1: "更多最新精彩资讯，感受企业魅力",

				//上一个显示的标题内容
				lastTitle: "",
				//下一个显示的标题内容
				nextTitle: "",
				//下一个的ID 用于跳转页面
				nextId: 0,
				//上一个的ID 用于跳转页面
				lastId: 0,
				// 控制按钮是否可以点击
				lastNoShow: "",
				nextNoShow: "",
			};
		},
		created() {
			this.id = this.$route.params.id
			this.getnew();

		},
		beforeRouteUpdate(to, from, next) {
			// 路由变化前执行，组件实例已经解析了，可以访问组件数据  
			this.getnew(); // 假设你的路由参数是 id  
			next();
		},
		methods: {
			getnew() {
				getGroup().then(response => {
					this.arrList = response.data;
					this.group = this.arrList[this.id];
					this.getAllList();
				});
			},
			getAllList() {
				let length = this.arrList.length;
				//上一步
				this.lastTitle = "无";
				this.lastNoShow = true;
				//下一步
				this.nextTitle = "无";
				this.nextNoShow = true;

				//有上一步
				if (this.id != 0 && length != 1) {
					this.lastTitle = this.arrList[parseInt(this.id) - 1].title;
					this.lastNoShow = false;
					this.lastId = parseInt(this.id) - 1;
				}
				//有下一步
				if (this.id < length - 1) {
					this.nextTitle = this.arrList[parseInt(this.id) + 1].title;
					this.nextId = parseInt(this.id) + 1;
					this.nextNoShow = false;
				}

			},
			//点击上一篇文章时;
			last() {
				this.id = this.lastId;
				this.$router.push({
					// 拼接 lastID 和我们定义的这个地址，就能实现跳转了
					path: "/newsxiangqing/" + this.lastId,
				});
			},
			//点击下一篇文章时
			next() {
				this.id = this.nextId;
				this.$router.push({
					path: "/newsxiangqing/" + this.nextId,
				});
			},
		},
	};
</script>
<style scoped>
	@charset "utf-8";

	/*=========================Reset_start==========================*/
	body,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	div,
	p,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	form,
	table,
	th,
	td,
	a,
	img,
	span,
	strong,
	var,
	em,
	input,
	textarea,
	select,
	option {
		margin: 0;
		padding: 0;
	}

	/*去内外边距*/
	html,
	body {
		font-family: "微软雅黑", "宋体", Arail, Tabhoma;
		text-align: left;
	}

	/*字体 文本居左*/
	ul {
		list-style: none;
	}

	/*去ul li 小点*/
	img {
		border: 0 none;
	}

	/*img IE6 兼容问题*/
	input,
	select,
	textarea {
		outline: 0;
	}

	/*去除点击蓝框*/
	textarea {
		resize: none;
		overflow: auto;
	}

	/*锁定多行文本框大小 与文字超出出现滚动条*/
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	/*表格边框合并 边框间距0*/
	th,
	strong,
	var,
	em {
		font-weight: normal;
		font-style: normal;
	}

	/*特殊文字粗细 样式显示正常*/
	a {
		text-decoration: none;
	}

	/*a标签去下划线*/
	.clearfix:after {
		clear: both;
		content: "";
		display: block;
		height: 0;
	}

	/*清浮动*/
	a {
		color: #000000;
	}

	/*==========================Reset_End===========================*/
	.container {
		width: calc(100vw - 20%);
		/* 100vw是视口宽度，减去两边的20%（即两边各10%） */
		margin: 0 auto;
		/* 居中显示，可选 */
		padding: 20px;
		/* 你可以根据需要添加内边距 */
		box-sizing: border-box;
		/* 让padding不影响元素的最终宽度 */
	}

	.youjiaopage_wrap {
		width: 100vw;
		margin: 0 auto;
		height: 1000px;
	}

	/* 面包屑 */
	.Crumbs {
		width: 1200px;
		height: 46px;
		margin: 0 auto;
	}

	.Crumbs {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 17px;
	}

	.Crumbs div {
		line-height: 46px;
	}

	.Crumbs span {
		color: #000000;
	}

	/* 新闻详情 */
	.NewsDetails_cont {
		width: 1200px;
		margin: 0 auto;
	}

	.NewsDetails_cont_title>h1 {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2b2b2b;
		line-height: 33px;
		margin-top: 78px;
		text-align: center;
	}

	.NewsDetails_cont_title img {
		vertical-align: middle;
	}

	.NewsDetails_cont_title_span {
		text-align: center;

		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #a3a3a3;
		line-height: 30px;
		margin-top: 8px;
	}

	.NewsDetails_cont_text {
		margin-top: 40px;
		border-top: 1px solid #eaeaea;
	}

	.NewsDetails_cont_text p {
		line-height: 30px;
		width: 1000px;
		margin: 40px 100px;
	}

	.NewsDetails_cont_text div {
		margin-left: 90px;
		font-size: 24px;
		font-weight: bold;
	}

	.NewsDetails_cont_img {
		text-align: center;
		margin-bottom: 40px;
	}

	.NewsDetails_cont_btm {
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
	}

	.NewsDetails_cont_btm div {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #222222;
		line-height: 33px;
	}

	.NewsDetails_cont_btm p {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 20px;
	}

	.NewsDetails_cont_btm2 {
		display: flex;
		justify-content: space-between;
	}

	.NewsDetails_cont_btm2_1 {
		margin: 32px 0;
		padding: 31px 61px 31px 20px;
		width: 507px;
		height: 123px;
		background: #ffffff;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
	}

	.NewsDetails_cont_btm2_1 div {
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 25px;
	}

	.NewsDetails_cont_btm2_1 p {
		margin-top: 10px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 28px;
	}

	.NewsDetails_cont_footer {
		padding: 0 40px;
		line-height: 68px;
		width: 1120px;
		height: 68px;
		background: #f8f8f8;
		display: flex;
		justify-content: space-between;
	}

	.btn {
		cursor: pointer;
	}

	.noClick {
		/* 不可点击 */
		pointer-events: none;
	}
	.talk_bottom {
		width: 1200px;
		margin: 91px auto;
		margin-bottom: 81px;
	}
	
	/* 富文本标签 */
	.talk_bottom >>> p {
		width: 100%;
	}
	
	.talk_bottom >>> img {
		max-width: 100%;
	}
	
	.talk_bottom >>> video {
		width: 100%;
	}
	
	.talk_bottom >>> b {
		width: 100%;
	}
	
	.talk_bottom >>> i {
		width: 100%;
	}
	
	.talk_bottom >>> u {
		width: 100%;
	}
	
	.talk_bottom >>> strong {
		width: 100%;
	}
	
	.talk_bottom >>> em {
		width: 100%;
	}
	
	.talk_bottom >>> font {
		width: 100%;
	}
	
	.talk_bottom >>> sup {
		width: 100%;
	}
	
	.talk_bottom >>> sub {
		width: 100%;
	}
	
	.talk_bottom >>> mark {
		width: 100%;
	}
	
	.talk_bottom >>> del {
		width: 100%;
	}
	
	.talk_bottom >>> ins {
		width: 100%;
	}
</style>