<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页> 开设课程 > <span>户外活动</span></div>
		</div>
		<div class="container" v-html="summercamp.content"></div>
		
		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import { getOutdoors } from "@/api/obtain";
export default {
	name: "outdoors",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			summercamp: null,
			h1: "户外运动",
			Img1: require("../assets/img/keji/矿山banner1.png"),
		};
	},
	created() {
		this.course();
	},
	methods: {
		course() {
			getOutdoors().then(response => {
				this.summercamp = response.data;
			});
		},
	},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/
.container {  
  width: calc(100vw - 20%); /* 100vw是视口宽度，减去两边的20%（即两边各10%） */  
  margin: 0 auto; /* 居中显示，可选 */  
  padding: 20px; /* 你可以根据需要添加内边距 */  
  box-sizing: border-box; /* 让padding不影响元素的最终宽度 */  
}  
.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 职教 */
.VocationalEducation {
	width: 100vw;
	height: 509px;
	background-color: #ffffff;
}
.VocationalEducation_cont {
	width: 1200px;
	margin: 0 auto;
}
.VocationalEducation_cont > h1 {
	text-align: center;
	margin: 65px 0 45px 0;
	font-size: 32px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 45px;
}
.VocationalEducation_cont_item {
	display: flex;
	justify-content: space-between;
}
.VocationalEducation_cont_item p {
	width: 600px;
	height: 280px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 40px;
}
.VocationalEducation_cont_item img {
	width: 500px;
	height: 300px;
}
/* 合作 */
.Cooperation {
	border: 1px dashed black;
	width: 100vw;
	height: 1074px;
	background-image: url("../assets/img/zhijiao/热区.png");
}
.Cooperation_cont {
	width: 1200px;
	margin: 0 auto;
}
.Cooperation_cont img {
	border: 1px dashed;
}
.Cooperation_cont > h1 {
	text-align: center;
	margin-top: 26px;
	font-size: 32px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 45px;
}
.Cooperation_cont_text {
	margin-top: 28px;
}
/* 特色 */
.Features {
	width: 100vw;
	height: 900px;
	background: #f8f8f8;
}
.Features_cont {
	width: 1200px;
	margin: 0 auto;
}
.Features_cont > h1 {
	text-align: center;
	padding-top: 60px;
	font-size: 32px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #771b1b;
	line-height: 45px;
}
.Features_cont_item {
	margin-top: 17px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.Features_cont_item1 {
	text-align: center;
	margin-top: 24px;

	width: 384px;
	height: 325px;
	background: #ffffff;
	border-radius: 8px;
}
.Features_cont_item1 img {
	border: 1px dashed;
	margin-top: 54px;
}
.Features_cont_item1 p {
	margin-top: 26px;
}
.Features_cont_item1 span {
	padding: 18px 40px 0 40px;

	text-align: left;
	display: block;
	width: 304px;
	height: 96px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #da1616;
	line-height: 24px;
}
/* 教学产品 */
.Teaching {
	width: 100vw;
	height: 1417px;
	background-color: #ffffff;
}
.Teaching_cont {
	width: 1200px;
	margin: 0 auto;
}
.Teaching_cont > h1 {
	text-align: center;
	margin-top: 80px;
	font-size: 32px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 45px;
}
.Teaching_cont_item {
	height: 1152px;
	margin-top: 90px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: space-between;
}
.Teaching_cont_item1 {
	text-align: center;
	width: 230px;
	height: 273px;
}
.Teaching_cont_item1 img {
	margin-top: 30px;

	width: 80px;
	height: 80px;
	border: 1px dashed;
}
.Teaching_cont_item1 p {
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 25px;
	margin: 32px 0 0 0;
}
.Teaching_cont_item1 span {
	width: 200px;
	height: 60px;
	margin-top: 16px;

	display: block;
	margin-left: 20px;
	text-align: left;

	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}
</style>
