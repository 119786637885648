import { render, staticRenderFns } from "./introduce.vue?vue&type=template&id=02e43c08&scoped=true"
import script from "./introduce.vue?vue&type=script&lang=js"
export * from "./introduce.vue?vue&type=script&lang=js"
import style0 from "./introduce.vue?vue&type=style&index=0&id=02e43c08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02e43c08",
  null
  
)

export default component.exports