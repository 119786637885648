<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页> 开设课程 > <span>体育运动</span></div>
		</div>
		<div class="container" v-html="motion.content"></div>
		

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import { getMotion } from "@/api/obtain";
export default {
	name: "sports",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			motion: null,
			h1: "体育运动",
			Img1: require("../assets/img/keji/矿山banner1.png"),

		};
	},
	created() {
		this.course();
	},
	methods: {
		course() {
			getMotion().then(response => {
				this.motion = response.data;
			});
		},
	},
	
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/
.container {  
  width: calc(100vw - 20%); /* 100vw是视口宽度，减去两边的20%（即两边各10%） */  
  margin: 0 auto; /* 居中显示，可选 */  
  padding: 20px; /* 你可以根据需要添加内边距 */  
  box-sizing: border-box; /* 让padding不影响元素的最终宽度 */  
}  
.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 幼儿板块 */
.Young {
	width: 100vw;
	height: 971px;
}
.Young_cont {
	width: 1200px;
	margin: 0 auto;
}
.Young_cont > h1 {
	font-size: 32px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 45px;
	text-align: center;
	margin-top: 61px;
}
.Young_cont p {
	margin-top: 83px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 32px;
}
.Young_cont_img {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
}
.Young_cont_img img {
	border: dashed;
	width: 585px;
	height: 340px;
}
/*合作模式  */
.Cooperation {
	border: dashed;
	width: 100vw;
	height: 831px;
	background-image: url("../assets/img/youjiao/摄图网_500924990_banner.png");
}
.Cooperation_cont > h1 {
	font-size: 32px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #772020;
	line-height: 45px;
	text-align: center;
	padding-top: 73px;
}
.Cooperation_cont {
	width: 1200px;
	margin: 0 auto;
}
.Cooperation_cont_item {
	width: 1200px;
	margin-top: 51px;
}
.Cooperation_cont_item_top1 {
	border-radius: 8px;

	width: 373px;
	height: 273px;
	background: #23337f;
}
.Cooperation_cont_item_top1 > h1 {
	text-align: center;
	padding-top: 51px;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #ffffff;
	line-height: 25px;
}
.Cooperation_cont_item_top1 p {
	width: 308px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 24px;
	margin: 22px 32px 0 32px;
}
.Cooperation_cont_item_top {
	display: flex;
	justify-content: space-between;
}
.Cooperation_cont_item_btm {
	width: 787px;
	margin: 36px 0 0 209px;
	display: flex;
	justify-content: space-between;
}
/* 优势特色 */
.Features_cont {
	width: 1200px;
	margin: 0 auto;
}
.Features_cont > h1 {
	text-align: center;
	margin: 80px 0 60px;
}
.Features_cont_item {
	border: dashed;
	display: flex;
}
.Features_cont_item_text {
	width: 749px;
	height: 460px;
	background: #f8f8f8;
}
.Features_cont_item_text > h1 {
	padding: 60px 64px 19px 64px;

	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.Features_cont_item_text p {
	padding-left: 64px;
	width: 622px;
	height: 288px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 32px;
}
/* 创新人才培养 */
.Cultivate_cont {
	width: 1200px;
	margin: 0 auto;
}
.Cultivate_cont > h1 {
	text-align: center;
	margin-top: 92px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.Cultivate_cont_item {
	height: 677px;
	margin-top: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: space-between;
}
.Cultivate_cont_item1 {
	border: dashed;
	position: relative;
	width: 580px;
	height: 199px;
}
.Cultivate_cont_item1 img {
	position: absolute;
}
.Cultivate_cont_item1 h1 {
	top: 30px;
	left: 40px;
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #23337f;
	line-height: 32px;
	position: absolute;
}
.Cultivate_cont_item1 p {
	top: 74px;
	left: 40px;
	width: 500px;
	height: 96px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 24px;
	position: absolute;
}
/* 体系 */
.System_cont {
	width: 1200px;
	height: 1620px;
	margin: 0 auto;
}
.System_cont > h1 {
	margin: 80px 0 60px 0;
	text-align: center;
}
.System_cont_item1 {
	margin-bottom: 40px;
	display: flex;
}
.System_cont_item1_text {
	width: 740px;
	height: 340px;
	background: #f8f8f8;
}
.System_cont_item1_text > h1 {
	padding: 57px 0 0 86px;

	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.System_cont_item1_text p {
	padding: 24px 0 0 86px;

	width: 568px;
	height: 168px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 28px;
}
</style>
