<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页> 开设课程 > <span>消防技术</span></div>
		</div>
		<div class="container" v-html="fighting.content"></div>

		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import { getFireFighting } from "@/api/obtain";
export default {
	name: "fireFighting",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			fighting: null,
			h1: "消防技术",
			Img1: require("../assets/img/keji/矿山banner1.png"),
		};
	},
	created() {
		this.course();
	},
	methods: {
		course() {
			getFireFighting().then(response => {
				this.fighting = response.data;
			});
		},
	},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/
.container {  
  width: calc(100vw - 20%); /* 100vw是视口宽度，减去两边的20%（即两边各10%） */  
  margin: 0 auto; /* 居中显示，可选 */  
  padding: 20px; /* 你可以根据需要添加内边距 */  
  box-sizing: border-box; /* 让padding不影响元素的最终宽度 */  
}  
.youjiaopage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
}
/* 面包屑 */
.Crumbs {
	width: 1200px;
	height: 46px;
	margin: 0 auto;
}
.Crumbs {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
	line-height: 17px;
}
.Crumbs div {
	line-height: 46px;
}
.Crumbs span {
	color: #000000;
}
/* 电商板块 */
.Electricity_cont {
	width: 1200px;
	margin: 0 auto;
	height: 2240px;
}
.Electricity_cont > h1 {
	text-align: center;
	margin: 65px 0 45px 0;
}
.Electricity_cont_item1 {
	display: flex;
	justify-content: space-between;
}
.Electricity_cont_item1 p {
	margin-top: 30px;
	width: 600px;
	height: 240px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #444444;
	line-height: 40px;
}
.Electricity_cont_item2 {
	position: relative;
	display: flex;
	margin-top: 80px;
}
.Electricity_cont_item2_text {
	position: absolute;
	top: 52px;
	left: 640px;
	width: 560px;
	height: 460px;
	background: #f8f8f8;
}
.Electricity_cont_item2_text > h1 {
	margin-top: 76px;
	margin-left: 52px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.Electricity_cont_item2_text p {
	margin-top: 19px;
	margin-left: 52px;

	width: 455px;
	height: 256px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 32px;
}
.Electricity_cont_item3 {
	position: relative;
	display: flex;
	margin-top: 137px;
}
.Electricity_cont_item3 img {
	position: absolute;
	right: 0;
}
.Electricity_cont_item3_text {
	position: absolute;
	top: 52px;
	width: 560px;
	height: 460px;
	background: #f8f8f8;
}
.Electricity_cont_item3_text > h1 {
	margin-top: 76px;
	margin-left: 52px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.Electricity_cont_item3_text p {
	margin-top: 19px;
	margin-left: 52px;

	width: 455px;
	height: 256px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 32px;
}

/* 4 */
.Electricity_cont_item4 {
	position: relative;
	display: flex;
	margin-top: 570px;
}
.Electricity_cont_item4_text {
	position: absolute;
	top: 52px;
	left: 640px;
	width: 560px;
	height: 480px;
	background: #f8f8f8;
}
.Electricity_cont_item4_text > h1 {
	margin-top: 76px;
	margin-left: 52px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #444444;
	line-height: 33px;
}
.Electricity_cont_item4_text p {
	margin-top: 19px;
	margin-left: 52px;

	width: 455px;
	height: 256px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 32px;
}
</style>
