<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页><span>招聘信息</span></div>
		</div>
		<!-- 招聘信息 -->
		<div class="Recruitment">
			<div class="Recruitment_cont">
				<div class="Recruitment_cont_item1" v-for="(item, index) in arrList" :key="index" @click="onurl(index)">
					<div class="Recruitment_cont_item1_p">
						<p>{{ item.p1 }}</p>
						<p>{{ item.p2 }}</p>
					</div>
					<div class="Recruitment_cont_item1_div1">
						<div>学历要求: {{ item.div1 }}</div>
						<div>工作经验: {{ item.div2 }}</div>
						<div>招聘人数: {{ item.div3 }} 人</div>
					</div>
					<div class="Recruitment_cont_item1_div1 div2">
						<div>
							工作地点: <span class="div1_span">{{ item.div4 }}</span>
						</div>
						<div>截止时间: {{ item.div5 }}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 翻页 -->
		<!--  <Pages></Pages> -->

		<!-- 申请职位 -->
		<!-- <applyFor></applyFor> -->

		<footer2 style="margin-top: 61px;"></footer2>

		<!-- end -->
	</div>
</template>


<script>
	import footer2 from "../components/footer.vue";
	import banner2 from "../components/banner.vue";
	/* import Pages from "../components/recruitInfoPages.vue"; */
	import nav2 from "../components/nav.vue";
	/* import applyFor from "../components/applyFor.vue"; */
	import {
		getRecruit
	} from "@/api/obtain";
	export default {
		name: "recruit",
		components: {
			footer2,
			nav2,
			banner2,
			/*    Pages,
			    applyFor, */
		},
		data() {
			return {
				h1: "招聘信息",
				Img1: require("../assets/img/keji/矿山banner1.png"),

				p1: "招贤纳士，成就自我价值",
				arrList: [],
			};
		},
		created() {
			this.getnew();
		},
		methods: {
			getnew() {
				getRecruit().then(response => {
					this.arrList = response.data;
				});
			},
			onurl(index) {
				this.$router.push("/recruitDetails/" + index);

			},
		},
	};
</script>
<style scoped>
	@import "../assets/css/recruit-info.css";
</style>