<template>
	<!-- star -->

	<div class="kejipage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<div class="Crumbs">
			<div>当前位置：首页> 开设课程 > <span>应急救护</span></div>
		</div>
		<div class="container" v-html="emergency.content"></div>

	
		<!-- footer -->
		<footer2></footer2>

		<!-- end -->
	</div>
</template>

<script>
	import footer2 from "../components/footer.vue";
	import banner2 from "../components/banner.vue";
	import nav2 from "../components/nav.vue";
	import { getEmergency } from "@/api/obtain";
	export default {
		name: "emergency",
		components: {
			footer2,
			nav2,
			banner2,
		},
		data() {
			return {
				emergency: null,
				h1: "开设课程 · 应急救护",
				Img1: require("../assets/img/keji/矿山banner1.png"),
				arrList: [],
			};
		},
		created() {
			this.course();
		},
		methods: {
			course() {
				getEmergency().then(response => {
					this.emergency = response.data;
				});
			},
		},
	};
</script>
<style scoped>
	@charset "utf-8";

	/*=========================Reset_start==========================*/
	body,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	div,
	p,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	form,
	table,
	th,
	td,
	a,
	img,
	span,
	strong,
	var,
	em,
	input,
	textarea,
	select,
	option {
		margin: 0;
		padding: 0;
	}

	/*去内外边距*/
	html,
	body {
		font-family: "微软雅黑", "宋体", Arail, Tabhoma;
		text-align: left;
	}

	/*字体 文本居左*/
	ul {
		list-style: none;
	}

	/*去ul li 小点*/
	img {
		border: 0 none;
	}

	/*img IE6 兼容问题*/
	input,
	select,
	textarea {
		outline: 0;
	}

	/*去除点击蓝框*/
	textarea {
		resize: none;
		overflow: auto;
	}

	/*锁定多行文本框大小 与文字超出出现滚动条*/
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	/*表格边框合并 边框间距0*/
	th,
	strong,
	var,
	em {
		font-weight: normal;
		font-style: normal;
	}

	/*特殊文字粗细 样式显示正常*/
	a {
		text-decoration: none;
	}

	/*a标签去下划线*/
	.clearfix:after {
		clear: both;
		content: "";
		display: block;
		height: 0;
	}

	/*清浮动*/
	a {
		color: #000000;
	}

	/*==========================Reset_End===========================*/
.container {  
  width: calc(100vw - 20%); /* 100vw是视口宽度，减去两边的20%（即两边各10%） */  
  margin: 0 auto; /* 居中显示，可选 */  
  padding: 20px; /* 你可以根据需要添加内边距 */  
  box-sizing: border-box; /* 让padding不影响元素的最终宽度 */  
}  
	.kejipage_wrap {
		width: 100vw;
		margin: 0 auto;
		height: 1000px;
	}

	/* 面包屑 */
	.Crumbs {
		width: 1200px;
		height: 46px;
		margin: 0 auto;
	}

	.Crumbs {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 17px;
	}

	.Crumbs div {
		line-height: 46px;
	}

	.Crumbs span {
		color: #000000;
	}

	/* 科技板块 */
	.TechnologySector_cont {
		width: 1200px;
		height: 810px;
		margin: 0 auto;
		padding-top: 64px;
	}

	.TechnologySector_cont h1 {
		font-size: 32px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 45px;
		text-align: center;
	}

	.TechnologySector_cont_bottom img {
		margin-top: 45px;
		float: right;
		width: 1000px;
		height: 520px;
	}

	.TechnologySector_cont_bottom_text {
		margin-top: -424px;
		float: left;
		width: 720px;
		height: 504px;
		background: #f8f8f8;
		z-index: 10;
	}

	.TechnologySector_cont_bottom_text div {
		margin-top: 36px;

		font-size: 16px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
		line-height: 32px;
	}

	.TechnologySector_cont_bottom_text p {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #444444;
		line-height: 35px;
		margin-top: 24px;
	}

	.TechnologySector_cont_bottom_text_english {
		font-size: 70px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #f8f8f8;
		line-height: 98px;
	}

	/* 教育 */
	.Education {
		width: 100vw;
		height: 1741px;
		background: #f8f8f8;
	}

	.Education_cont {
		width: 1200px;
		margin: 0 auto;
		padding-top: 91px;
	}

	.Education_cont h1 {
		text-align: center;
		font-size: 32px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 45px;
	}

	.Education_item {
		margin-top: 45px;
	}

	.Education_item1 {
		display: flex;
	}

	.Education_item_text {
		background: #ffffff;
		width: 740px;
		height: 340px;
	}

	.Education_item_text p {
		margin-top: 72px;
		margin-left: 86px;
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: red;
		line-height: 33px;
	}

	.Education_item_text span {
		width: 568px;
		height: 140px;
		margin-top: 24px;
		margin-left: 86px;

		display: inline-block;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #444444;
		line-height: 28px;
	}

	.Education_item1 {
		margin-bottom: 40px;
	}

	.Education_item1_block {
		width: 550px;
		display: flex;
		flex-wrap: wrap;
		margin-left: 86px;
	}

	.Education_item1_block .div3 {
		margin: 40px 24px 38px 0;
		line-height: 40px;
		text-align: center;
		width: 172px;
		height: 40px;
		background: #ffffff;
		border-radius: 4px;
		border: 1px solid #d7d7d7;
	}

	.Education_item1_block .div4 {
		margin-top: -10px;
	}

	.Education_item1_block .div5 {
		margin-top: -10px;
	}

	.Education_item1_block div {
		margin: 40px 24px 38px 0;
		line-height: 40px;

		text-align: center;
		width: 140px;
		height: 40px;
		background: #ffffff;
		border-radius: 4px;
		border: 1px solid #d7d7d7;
	}

	/* 城市 */
	.Citi {
		width: 100vw;
		height: 883px;
		background-image: url("../assets/img/keji/蒙版.png");
	}

	.Citi_cont {
		width: 1200px;
		margin: 0 auto;
	}

	.Citi_cont>h1 {
		text-align: center;
		margin-top: 83px;

		font-size: 32px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 45px;
	}

	.Citi_cont_p {
		text-align: center;
		margin-top: 40px;

		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #444444;
		line-height: 32px;
	}

	.Citi_cont_item {
		display: flex;
		justify-content: space-between;
		width: 1200px;
		margin-top: 64px;
	}

	.Citi_cont_item1 {
		width: 304px;
	}

	.Citi_cont_item1 p {
		margin: 24px 0;
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #23337f;
		line-height: 25px;
	}

	.Citi_cont_item1 span {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 24px;
	}

	/* 华为 */
	.HuaWei {
		width: 100vw;
		height: 809px;
		background: #ffffff;
	}

	.HuaWei_cont {
		padding-top: 80px;
		width: 1200px;
		margin: 0 auto;
	}

	.HuaWei_cont>h1 {
		text-align: center;
		font-size: 32px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 45px;
	}

	.HuaWei_cont_p {
		margin-top: 60px;
		text-align: center;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 22px;
	}

	.HuaWei_cont_item {
		display: flex;
		margin-top: 81px;
		margin-left: 150px;
	}

	.HuaWei_cont_item_img img {
		text-align: center;
		width: 320px;
		height: 326px;
	}

	.HuaWei_cont_item_l1 {
		text-align: right;

		margin-right: 41px;
	}

	.HuaWei_cont_item_l1 p {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 33px;
		margin-left: 180px;
	}

	.HuaWei_cont_item_l1 span {
		float: right;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 20px;
	}

	.HuaWei_cont_item_l2 {
		text-align: right;
		margin-right: 41px;

		margin-top: 90px;
	}

	.HuaWei_cont_item_l2 p {
		margin-left: 180px;
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 33px;
		margin-left: 180px;
	}

	.HuaWei_cont_item_l2 span {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 20px;
	}

	.HuaWei_cont_item_r {
		margin-left: 16px;
	}

	.HuaWei_cont_item_r1 p {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 33px;
	}

	.HuaWei_cont_item_r1 span {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 20px;
	}

	.HuaWei_cont_item_r2 {
		margin-top: 90px;
	}

	.HuaWei_cont_item_r2 p {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 33px;
	}

	.HuaWei_cont_item_r2 span {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 20px;
	}

	.HuaWei_cont_item_c {
		margin-top: 41px;
		text-align: center;
	}

	.HuaWei_cont_item_c p {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 33px;
	}

	.HuaWei_cont_item_c span {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 20px;
	}
</style>