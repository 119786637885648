<template>
	<div class="GroupOverview">
		<daohang></daohang>
		<banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2>
		<!-- 关于企业 -->
		<div class="about-bg" >
			<div class="about" id="about">
				<div class="about-top">
					<div class="about-top-l talk_bottom">
						<h1> 关于企业 </h1>
						<div  v-html="enterprise.content"></div>
					</div>
					
				</div>	
			</div>
		</div>
		<!-- 企业文化 -->
		<div class="qywh" id="CultureInfo">
			<h1>企业文化</h1>
			<div class="qywh-item">
				<div class="qywh-item1">
					<span><img src="../assets/img/编组 5.png" alt="" /></span>
					<p class="qywh-text">企业定位</p>
					<p class="qywh-text2">
						{{configs.location}}
					</p>
				</div>
				<div class="qywh-item1">
					<span><img src="../assets/img/编组 10.png" alt="" /></span>
					<p class="qywh-text">企业理念</p>
					<p class="qywh-text2 text2-2">
						{{configs.idea}}
					</p>
				</div>
				<div class="qywh-item1">
					<span><img src="../assets/img/编组 14.png" alt="" /></span>
					<p class="qywh-text">企业使命</p>
					<p class="qywh-text2">
						{{configs.mission}}
					</p>
				</div>
				<div class="qywh-item1">
					<span><img src="../assets/img/编组 15.png" alt="" /></span>
					<p class="qywh-text">企业愿景</p>
					<p class="qywh-text2">
						{{configs.vision}}
					</p>
				</div>
			</div>
		</div>
		<!-- 总裁寄语 -->
		<div class="President">
			<div class="President_content">
				<div class="President-title">
					<p>总裁寄语</p>
				</div>
				<div class="President-text">
					<p>
						{{configs.consult2}}
					</p>

					<div class="President-text2">
						<span>执行总裁</span>
						<img src="../assets/img/杨刚.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<!-- 发展历程 -->
	<!-- 	<div class="DevelopmentHistory" id="history">
			<div class="DevelopmentHistory_cont">
				<h1>发展历程</h1>
				<div class="DevelopmentHistory_cont_img">
					<img src="../assets/img/leftjiantou2.png" class="DevelopmentHistory_cont_img1" />
					<img src="../assets/img/rightjiantou.png" class="DevelopmentHistory_cont_img2" />
				</div>
				<div class="DevelopmentHistory_cont_ul">
					<ul>
						<p>2010</p>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
					</ul>
					<ul>
						<p>2010</p>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
					</ul>
					<ul>
						<p>2010</p>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
					</ul>
					<ul>
						<p>2010</p>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
					</ul>
					<ul>
						<p>2010</p>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
						<li>全年收入超过1亿人民币</li>
					</ul>
				</div>
			</div>
		</div> -->
		<!-- 关于企业 -->
		<div class="DevelopmentHistory" id="history">
			<div class="about" id="about">
				<div class="about-top">
					<div class="about-top-l">
						<h1> 师资力量 </h1>
						<div v-html="enterprise.content"></div>
					</div>
					
				</div>	
			</div>
		</div>
		<!-- 底部 -->
		<footer2></footer2>
	</div>
</template>

<script>
	import daohang from "../components/nav.vue";
	import footer2 from "../components/footer.vue";
	import banner2 from "../components/banner.vue";
	import {
		config,
		getEnterprise,
		getTeachers,
	} from "@/api/obtain";
	export default {
		name: "App",
		data() {
			return {
				h1: "企业概况",
				P1: "欢迎加入我们",
				Img1: require("../assets/img/bannerjituan.png"),
				//师资力量
				teachers: null,
				//关于企业
				enterprise: null,
				//配置
				configs: null,
			};
		},
		components: {
			daohang,
			footer2,
			banner2,
		},
		created() {
			this.$nextTick(() => {
				this.toLocal();
			});
			this.config();
		},
		mounted() {
			let _this = this;
			_this.$nextTick(function() {
				window.addEventListener("scroll", _this.handleScroll);
			});

		},
		methods: {
			config() {
				config().then(response => {
					this.configs = response.data;
				});
				getEnterprise().then(response => {
					this.enterprise = response.data;
				});
				getTeachers().then(response => {
					this.teachers = response.data;
				});




			},
			toLocal() {
				//查找存储的锚点id
				let Id = localStorage.getItem("toId");
				let toElement = document.getElementById(Id);
				//锚点存在跳转
				if (Id) {
					toElement.scrollIntoView();
					localStorage.setItem("toId", "");
				}
			},
		},
	};
</script>

<style  scoped>
	.GroupOverview {
		width: 100vw;
		margin: 0 auto;
	}

	.about {
		
		position: relative;
		margin: 100px 300px;
	}

	.about-bg {
		background-image: url("../assets/img/蒙版.png");
	}

	.about-top {
		position: relative;
		display: flex;
	}

	
	
	.talk_bottom {
		width: 1200px;
		margin: 91px auto;
		margin-bottom: 81px;
	}	
	/* 富文本标签 */
	.about-top-l >>> p {
		max-width: 100%;
	}
	
	.about-top-l >>> img {
		max-width: 100%;
	}
	
	.about-top-l >>> video {
		max-width: 100%;
	}
	
	.about-top-l >>> b {
		max-width: 100%;
	}
	
	.about-top-l >>> i {
		max-width: 100%;
	}
	
	.about-top-l >>> u {
		max-width: 100%;
	}
	
	.about-top-l >>> strong {
		max-width: 100%;
	}
	
	.about-top-l >>> em {
		max-width: 100%;
	}
	
	.about-top-l >>> font {
		max-width: 100%;
	}
	
	.about-top-l >>> sup {
		max-width: 100%;
	}
	
	.about-top-l >>> sub {
		max-width: 100%;
	}
	
	.about-top-l >>> mark {
		max-width: 100%;
	}
	
	.about-top-l >>> del {
		max-width: 100%;
	}
	
	.about-top-l >>> ins {
		max-width: 100%;
	}
	

	.about-top-l>span {
		font-size: 32px;
	}

	.about-top-l>p {
		font-size: 16px;
		line-height: 40px;
	}

	.img-yinying {
		position: absolute;
		top: 58px;
		left: -17px;
		width: 17px;
		height: 408px;
		background: #f8f8f8;
	}

	.about-top-r {
		position: absolute;
		right: -110px;
	}

	.about-top-r img {
		width: 487px;
		height: 517px;
	}

	.about-down {
		position: absolute;
		bottom: 20px;
	}

	.item {
		top: 236px;
		position: relative;
	}

	.item2 {
		top: 158px;
		left: 328px;
		position: relative;
	}

	.item3 {
		top: 79px;
		left: 645px;
		position: relative;
	}

	.item4 {
		left: 1049px;
		position: relative;
	}

	.item-1 {
		color: rgba(35, 51, 127, 1);
		font-size: 60px;
	}

	.item-2 {
		position: absolute;
		color: rgba(35, 51, 127, 1);
		font-size: 16.8px;
	}

	.item-3 {
		position: absolute;
		left: 88px;
		top: 41px;
		color: rgba(102, 102, 102, 1);
		font-size: 20px;
		width: 100px;
	}

	.item2-3 {
		position: relative;
		color: rgba(102, 102, 102, 1);
		left: 18px;
		font-size: 20px;
	}

	.item3-3 {
		position: relative;
		color: rgba(102, 102, 102, 1);
		left: 18px;
		font-size: 20px;
	}

	.item4-3 {
		position: relative;
		color: rgba(102, 102, 102, 1);
		left: 18px;
		font-size: 20px;
	}

	.qywh {
		width: 100%;
		height: 598px;
		background-color: rgba(248, 248, 248, 1);
	}

	.qywh h1 {
		color: rgba(68, 68, 68, 1);
		font-size: 32px;
		line-height: 134px;
		text-align: center;
	}

	.qywh-item {
		margin: 30px 200px;

		justify-content: space-between;
		display: flex;
	}

	.qywh-item1 {
		margin-right: 16px;
		position: relative;
		width: 288px;
		height: 348px;
		background-color: rgba(255, 255, 255, 1);
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
	}

	.qywh-item1 span {
		position: absolute;
		left: 91px;
		top: 30px;
		display: inline-block;
		border-radius: 100%;
		width: 90px;
		height: 90px;
		background-color: rgba(52, 145, 229, 1);
	}

	.qywh-item1 span>img {
		border-radius: 100%;
		height: 100%;
		width: 100%;
	}

	.qywh-text {
		position: absolute;
		top: 138px;
		left: 91px;
		width: 100px;
		color: rgba(68, 68, 68, 1);
		font-size: 24px;
	}

	.qywh-text2 {
		position: absolute;
		top: 192px;
		left: 10px;
		text-align: center;
		color: rgba(68, 68, 68, 1);
		font-size: 18px;
		line-height: 28px;
	}

	.text2-2 {
		width: 91px;
		line-height: 30px;
		position: absolute;
		left: 92px;
	}

	/* 总裁寄语 */
	.President {
		width: 100%px;
		height: 801px;
		background-image: url(../assets/img/摄图网_500941399_banner_企业文化背景（非企业商用）.png);
		background-size: 100% 801px;
		padding-top: 84px;
	}

	.President-title>p {
		font-size: 32px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #ffffff;
		line-height: 45px;
		text-align: center;
	}

	.President-text {
		width: 1200px;
		height: 465px;
		margin: 55px auto;
		background: #ffffff;
		box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
	}

	.President-text>p {
		width: 1000px;
		height: 262px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 40px;
		margin: 0 auto;
		padding-top: 71px;
	}

	.President-text2 {
		height: 50px;
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #222222;
		line-height: 33px;
		text-align: center;
		margin-top: 42px;
		position: relative;
	}

	.President-text2>span {
		position: absolute;
		left: 491px;
		top: 15px;
	}

	.President-text2>img {
		position: absolute;
	}

	/* 发展历程 */
	.DevelopmentHistory {
		width: 1920;
		background-image: url("../assets/img/fazhan_bg.png");
	}

	.DevelopmentHistory_cont {
		width: 1200px;
		margin: 0 auto;
	}

	.DevelopmentHistory_cont_img img {
		margin-top: 61px;
		width: 32px;
		height: 32px;
	}

	.DevelopmentHistory_cont_img1 {
		float: left;
	}

	.DevelopmentHistory_cont_img2 {
		float: right;
	}

	.DevelopmentHistory_cont>h1 {
		text-align: center;
		margin-top: 64px;

		font-size: 32px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #444444;
		line-height: 45px;
	}

	.DevelopmentHistory_cont_ul {
		display: flex;
		margin-top: 12px;
		margin-left: 59px;
		justify-content: space-around;
	}

	.DevelopmentHistory_cont_ul ul p {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #23337f;
		line-height: 33px;
	}

	.DevelopmentHistory_cont_ul li {
		margin-top: 8px;
		list-style-type: none;

		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #444444;
		line-height: 24px;
	}
	
</style>