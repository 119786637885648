import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import introduce from "../views/introduce.vue";
import emergency from "../views/emergency.vue";
import sports from "../views/sports.vue";
import study from "../views/study.vue";
import camp from "../views/camp.vue";
import outdoors from "../views/outdoors.vue";
import fireFighting from "../views/fireFighting.vue";
import news from "../views/news.vue";
import GroupNews from "../views/News/GroupNews.vue";
import MediaCoverage from "../views/News/MediaCoverage.vue";
import TradeNews from "../views/News/TradeNews.vue";
import newsDetail from "../views/newsxiangqing.vue";
import newsDetail1 from "../views/NewsDetals/newsxiangqing1.vue";
import newsDetail2 from "../views/NewsDetals/newsxiangqing2.vue";
import MediaDetail from "../views/MediaDetail.vue";
import MediaCoverage1 from "../views/NewsDetals/MediaCoverage1.vue";
import TradeDetail from "../views/TradeDetail.vue";
import TradeNews1 from "../views/NewsDetals/TradeNews1.vue";
import recruit from "../views/recruit.vue";
import zhaopinxinxi1 from "../views/Recruit-info/zhaopinxinxi1.vue";
import contact from "../views/contact.vue";
import recruitDetails from "../views/recruitDetails.vue";
import partner from "../views/partner.vue";


// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "index",
		component: index,
		meta: {
			title: "首页",
		},
	},
	{
		path: "/introduce",
		name: "introduce",
		component: introduce,
		meta: {
			title: "集团概况",
		},
	},
	{
		path: "/emergency",
		name: "emergency",
		component: emergency,
		meta: {
			title: "应急救护",
		},
	},
	{
		path: "/sports",
		name: "sports",
		component: sports,
		meta: {
			title: "体育运动",
		},
	},
	{
		path: "/study",
		name: "study",
		component: study,
		meta: {
			title: "研学旅行",
		},
	},
	{
		path: "/camp",
		name: "camp",
		component: camp,
		meta: {
			title: "冬夏令营",
		},
	},
	{
		path: "/outdoors",
		name: "outdoors",
		component: outdoors,
		meta: {
			title: "户外运动",
		},
	},
	{
		path: "/fireFighting",
		name: "fireFighting",
		component: fireFighting,
		meta: {
			title: "消防技术",
		},
	},
	{
		path: "/xinwen",
		name: "xinwen",
		component: news,
		children: [
			{
				path: "",
				component: GroupNews,
				name: "GroupNews",
				meta: {
					title: "企业新闻",
				},
			},
			{
				path: "MediaCoverage",
				component: MediaCoverage,
				name: "MediaCoverage",
				meta: {
					title: "媒体报道",
				},
			},
			{
				path: "TradeNews",
				name: "TradeNews",
				component: TradeNews,
				meta: {
					title: "行业资讯",
				},
			},
		],
		meta: {
			title: "企业新闻",
		},
	},
	{
		path: "/newsxiangqing/:id",
		name: "newsDetal",
		component: newsDetail,
		meta: {
			title: "新闻详情",
		},
	},
	{
		path: "/newsxiangqing1",
		name: "newsDetal1",
		component: newsDetail1,
		meta: {
			title: "新闻详情1",
		},
	},
	{
		path: "/newsxiangqing2",
		name: "newsDetal2",
		component: newsDetail2,
		meta: {
			title: "新闻详情2",
		},
	},
	{
		path: "/mediaDetail/:id",
		name: "MediaDetail",
		component: MediaDetail,
		meta: {
			title: "媒体报道详情",
		},
	},
	{
		path: "/MediaCoverage1",
		name: "MediaCoverage1",
		component: MediaCoverage1,
		meta: {
			title: "媒体报道1",
		},
	},
	{
		path: "/tradeDetail/:id",
		name: "TradeDetail",
		component: TradeDetail,
		meta: {
			title: "行业资讯详情",
		},
	},
	{
		path: "/TradeNews1",
		name: "TradeNews1",
		component: TradeNews1,
		meta: {
			title: "行业资讯1",
		},
	},
	{
		path: "/recruit",
		name: "recruit",
		component: recruit,
		meta: {
			title: "招聘信息",
		},
	},
	{
		path: "/partner/:id",
		name: "partner",
		component: partner,
		meta: {
			title: "合作伙伴",
		},
	},
	{
		path: "/zhaopinxinxi1",
		name: "zhaopinxinxi1",
		component: zhaopinxinxi1,
		meta: {
			title: "招聘信息1",
		},
	},
	{
		path: "/contact",
		name: "contact",
		component: contact,
		meta: {
			title: "联系我们",
		},
	},
	{
		path: "/recruitDetails/:id",
		name: "recruitDetails",
		component: recruitDetails,
		meta: {
			title: "招聘详情",
		},
	},
	
	
];
// title

const router = new VueRouter({
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});
